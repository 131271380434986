import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import Calendar from "../Calendar/Calendar";
import FlashSlotRow from "./FlashSlotRow";
import { ReactComponent as ChevronLeft } from "../assets/chevron.left.svg";

function MobileFlashCalendar({
  bookingData,
  flash,
  request,
  setRequest,
  toDetail,
  toUserInfo,
}) {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [slots, setSlots] = useState(null);
  const [slotsForDay, setSlotsForDay] = useState([]);
  const [minApptTime, setMinApptTime] = useState(null);

  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();

  const selectedDateString = selectedDate.toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const setDate = (date) => {
    setSelectedDate(date);
  };

  const setTime = (date) => {
    setSelectedTime(date);
  };

  function setSlot(slot) {
    setSelectedSlot(slot);
  }

  useEffect(() => {
    console.log("selectedDate", selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (slotsForDay.length >= 1) {
      setSelectedSlot(slotsForDay[0]);
    }
  }, [slotsForDay]);

  useEffect(() => {
    if (request && request.date) {
      setSelectedDate(request.startDate);
    }
    let base64String = atob(bookingData);
    let decodedBookingData;
    try {
      decodedBookingData = JSON.parse(base64String);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
    setMinApptTime(decodedBookingData.minApptTime);
    const slotsWithDates = decodedBookingData.availableSlots.map((slot) => ({
      ...slot,
      start_of_day: new Date(slot.startString).setHours(0, 0, 0, 0), // convert to JavaScript Date
    }));

    const compatSlots = slotsWithDates.filter(
      (slot) => slot.minutes >= flash.fields.duration.value * 60
    );

    setSlots(compatSlots);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (selectedDate !== null && !isLoading) {
      const selectedDateStartofDay = selectedDate.setHours(0, 0, 0, 0);
      const selectedDaySlots = slots.filter(
        (slot) => slot.start_of_day === selectedDateStartofDay
      );
      if (JSON.stringify(selectedDaySlots) !== JSON.stringify(slotsForDay)) {
        setSlotsForDay(selectedDaySlots);
      }
    }
  }, [selectedDate, slots, date]);

  const container = {
    zIndex: 1,
    position: "absolute",
    top: 128,
    left: (window.innerWidth - 360) / 2,
    width: "360px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "0",
    borderRadius: "3vmin",
    maxHeight: "500px",
    backgroundColor: colorScheme.systemGray6,
  };

  const calendarContainer = {
    zIndex: 2,
    position: "absolute",
    top: 32,
    left: (window.innerWidth - 296) / 2,
    width: "296px",
    height: "280px",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    paddingTop: "8px",
    borderRadius: "16px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.systemGray6,
  };

  const noSlotStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 10px",
    borderRadius: "10px",
    padding: "10px",
    color: "gray",
    backgroundColor: colorScheme.systemGray6,
  };

  const rowContainer = {
    textAlign: "center",
    fontWeight: "500",
    marginBottom: "24px",
    gap: "8px",
    overflowY: "scroll",
  };

  const buttonContainer = {
    width: "312px",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    //backgroundColor: "pink",
  };

  const arrowButtonStyle = {
    zIndex: 1,
    position: "relative",
    width: "48px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    color: "white",
    top: "24px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  const bookButtonStyle = {
    zIndex: 2,
    position: "relative",
    width: "96px",
    height: "40px",
    fontSize: "16px",
    color: "white",
    top: "24px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={calendarContainer}>
        <Calendar
          slots={slots}
          selectedDate={selectedDate}
          setSelectedDate={setDate}
        />
      </div>

      <div style={container}>
        <div style={{ height: 208 }}></div>
        <div style={rowContainer}>
          {selectedDate !== null && (
            <div>
              <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                {selectedDateString}
              </div>
              {slotsForDay.length > 0 ? (
                slotsForDay.map((slot, index) => (
                  <button
                    onClick={() => setSelectedSlot(slot)}
                    style={{
                      marginTop: "8px",
                      backgroundColor: "rgba(0, 0, 0, 0.0)",
                      border: "none",
                    }}
                  >
                    <FlashSlotRow
                      key={index}
                      request={request}
                      setRequest={setRequest}
                      slot={slot}
                      setSlot={setSlot}
                      isSelected={slot == selectedSlot}
                      setTime={setTime}
                      //onClick={() => setSelectedSlot(slot)}
                    />
                  </button>
                ))
              ) : (
                <div style={noSlotStyle}>No availability on this day.</div>
              )}
            </div>
          )}
        </div>

        <div style={buttonContainer}>
          <button style={arrowButtonStyle} onClick={() => toDetail()}>
            <ChevronLeft width="24px" height="24px" fill="#fff" />
          </button>

          <button style={bookButtonStyle} onClick={() => toUserInfo()}>
            Select
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobileFlashCalendar;
