import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import ImageCarousel from "./ImageCarousel";

let images;

function Portfolio({ portfolioRecordNames }) {
  const { colorScheme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [portfolio, setPortfolio] = useState(null);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const extractRecordNames = (arr) => {
    return arr.map((item) => item.split("*")[0]);
  };

  useEffect(() => {
    const recordNames = extractRecordNames(portfolioRecordNames);
    fetch("https://t1ov9ij1mh.execute-api.us-east-1.amazonaws.com/Prod", {
      method: "POST",
      body: JSON.stringify({ recordNames: recordNames }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        setPortfolio(data.records);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const galleryStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "10px",
    width: "92%",
    margin: "0 auto",
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleCloseCarousel = () => setSelectedImage(null);
  if (selectedImage !== null) {
    return (
      <ImageCarousel
        portfolio={portfolio}
        initialIndex={selectedImage}
        onClose={handleCloseCarousel}
      />
    );
  }

  if (portfolio) {
    return (
      <div style={galleryStyle}>
        {portfolio.map((imageSet, index) => (
          <div
            key={index}
            style={{
              width: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                paddingBottom: "100%",
                position: "relative",
              }}
              onClick={() => setSelectedImage(index)}
            >
              <img
                src={imageSet.fields.thumbnail.value.downloadURL}
                alt=""
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <p>No Portfolio Found</p>;
  }
}

export default Portfolio;
