import React, { useContext } from "react";
import { ThemeContext } from "../ThemeContext";

const BookingRow = ({ slot }) => {
  const { colorScheme } = useContext(ThemeContext);
  let start = new Date(slot.startString);
  let end = new Date(slot.endString);
  let dateStart = start.toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "2-digit",
  });
  let dateEnd = end.toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "2-digit",
  });

  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    marginBottom: "8px",
    padding: "8px",
    borderRadius: "8px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    color: colorScheme.primary,
    backgroundColor: colorScheme.systemGray6,
  };

  const timeStyle = {
    fontSize: "16px",
    borderRadius: "10px",
    color: colorScheme.primary,
  };

  const selectStyle = {
    fontSize: "16px",
    padding: "4px 8px",
    marginLeft: "8px",
    borderRadius: "4px",
    color: "white",
    backgroundColor: colorScheme.accent,
  };

  return (
    <div style={rowStyle}>
      <div style={timeStyle}>
        {dateStart} - {dateEnd}
      </div>
      <div style={selectStyle}>Select</div>
    </div>
  );
};

export default BookingRow;
