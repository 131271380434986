import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import { ReactComponent as ChevronLeft } from "../assets/chevron.left.svg";

function MobileBookingUserInfo({
  request,
  setRequest,
  toBookingInfo,
  handleSubmit,
  isSubmitting,
}) {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dobValue, setDOBValue] = useState(null);

  let dateString = request.startDate.toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  let startTime = request.startDate.toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "2-digit",
  });
  let endTime = request.endDate.toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "2-digit",
  });

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const updateRequest = (key, newValue) => {
    setRequest((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const handleDOB = (newDOB) => {
    setDOBValue(newDOB);
    let dobString;
    if (newDOB !== "") {
      const [year, month, day] = newDOB.split("-").map(Number);
      let dobDate = new Date(year, month - 1, day);
      dobString = dobDate.toISOString();
    }
    updateRequest("dob", dobString);
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    if (value.length > 3 && value.length <= 6)
      value = `(${value.substring(0, 3)}) ${value.substring(3)}`;
    else if (value.length > 6)
      value = `(${value.substring(0, 3)}) ${value.substring(
        3,
        6
      )}-${value.substring(6)}`;
    updateRequest("phone", value);
  };

  const cardContainer = {
    zIndex: 3,
    position: "absolute",
    top: "24px",
    left: (window.innerWidth - 296) / 2,
    width: "296px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.systemGray6,
  };

  const cardStyle = {
    fontSize: "flex",
    fontWeight: "500",
    margin: "2px",
    opacity: "0.8",
  };

  const container = {
    zIndex: 1,
    position: "absolute",
    top: "48px",
    left: (window.innerWidth - 360) / 2,
    width: "360px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "16px",
    backgroundColor: colorScheme.systemGray6,
  };

  const inputContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    //backgroundColor: "yellow",
  };

  const inputLabel = {
    fontSize: "8px",
    fontWeight: "600",
    marginLeft: "8px",
    margin: "2px",
    color: "gray",
    //backgroundColor: "red",
  };

  const inputStyle = {
    fontSize: "16px",
    border: "1px solid gray",
    borderRadius: "4px",
    backgroundColor: colorScheme.systemGray5,
  };

  const buttonContainer = {
    width: "312px",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    //backgroundColor: "pink",
  };

  const button = {
    position: "relative",
    height: "40px",
    fontSize: "16px",
    color: "white",
    top: "24px",
    cursor: "pointer",
    border: "none",
    borderRadius: "4px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  const arrowButton = {
    width: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const spinner = {
    border: "4px solid rgba(255, 255, 255, 0.3)",
    borderRadius: "50%",
    borderTop: "4px solid white",
    width: "16px",
    height: "16px",
    animation: "spin 1s linear infinite",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const styles = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  `;
  document.head.insertAdjacentHTML("beforeend", `<style>${styles}</style>`);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={cardContainer}>
        <p style={{ ...cardStyle, marginTop: "8px" }}>{dateString}</p>
        <p style={{ ...cardStyle, marginBottom: "8px" }}>
          {startTime} - {endTime}
        </p>
      </div>

      <div style={container}>
        <div style={{ height: "48px" }}></div>

        <div style={{ ...inputContainer, marginBottom: "8px" }}>
          <label style={inputLabel} htmlFor="date of birth">
            Date of Birth
          </label>
          <input
            value={dobValue}
            onChange={(e) => handleDOB(e.target.value)}
            style={{ ...inputStyle, width: "256px" }}
            type="date"
            id="dob"
            name="dob"
          />
        </div>

        <div style={{ ...inputContainer, marginBottom: "8px" }}>
          <label style={inputLabel} htmlFor="givenName">
            First Name
          </label>
          <input
            value={request.givenName}
            onChange={(e) => updateRequest("givenName", e.target.value)}
            style={{ ...inputStyle, width: "256px" }}
            type="text"
            id="givenName"
            name="givenName"
          />
        </div>

        <div style={{ ...inputContainer, marginBottom: "8px" }}>
          <label style={inputLabel} htmlFor="familyName">
            Last Name
          </label>
          <input
            value={request.familyName}
            onChange={(e) => updateRequest("familyName", e.target.value)}
            style={{ ...inputStyle, width: "256px" }}
            type="text"
            id="familyName"
            name="familyName"
          />
        </div>

        <div style={{ ...inputContainer, marginBottom: "8px" }}>
          <label style={inputLabel} htmlFor="tel">
            Phone
          </label>
          <input
            value={request.phone}
            onChange={handlePhoneChange}
            style={{ ...inputStyle, width: "256px" }}
            type="text"
            id="phone"
            name="phone"
            maxLength="14"
          />
        </div>

        <div style={{ ...inputContainer, marginBottom: "8px" }}>
          <label style={inputLabel} htmlFor="email">
            Email
          </label>
          <input
            value={request.email}
            onChange={(e) => updateRequest("email", e.target.value)}
            style={{ ...inputStyle, width: "256px" }}
            type="email"
            id="email"
            name="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          />
        </div>

        <div style={buttonContainer}>
          <button
            style={{ ...button, ...arrowButton }}
            onClick={() => toBookingInfo()}
          >
            <ChevronLeft width="24px" height="24px" fill="#fff" />
          </button>

          <button
            style={{ ...button, width: "96px" }}
            onClick={() => handleSubmit()}
          >
            {isSubmitting ? <div style={spinner}></div> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobileBookingUserInfo;
