import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "./ThemeContext";
import Profile from "./Profile";
import Booking from "./Booking/Booking";
import Flash from "./Flash/Flash";
import Portfolio from "./Portfolio/Portfolio";
import Contact from "./Contact/Contact";

import InstagramLogo from "./assets/instagram-sm.png";
import TikTokLogo from "./assets/tiktok-sm.png";
import XLogo from "./assets/x-sm.png";
import TwitterLogo from "./assets/twitter-sm.png";
import YouTubeLogo from "./assets/youtube-sm.png";
import FacebookLogo from "./assets/facebook-sm.png";
import OnlyfansLogo from "./assets/onlyfans-sm.png";

function Home({ profile, setSlot, flashData, setFlashData, flashIndex }) {
  const { darkMode, setDarkMode, colorScheme } = useContext(ThemeContext);
  const [view, setView] = useState("booking");
  const [bookingData, setBookingData] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const socialLogos = {
    instagram: InstagramLogo,
    tiktok: TikTokLogo,
    x: XLogo,
    twitter: TwitterLogo,
    youtube: YouTubeLogo,
    facebook: FacebookLogo,
    onlyfans: OnlyfansLogo,
  };

  function getPlatformName(url) {
    const urlObj = new URL(url);
    const platform = urlObj.hostname.split(".")[1]; // This assumes URLs are in the format "www.platform.com"
    return platform;
  }

  const socialLinks = profile.fields.links.value.filter(
    (link) => !link.startsWith("*") && socialLogos[getPlatformName(link)]
  );

  const customLinks = profile.fields.links.value.filter((link) =>
    link.startsWith("*")
  );

  const handleViewChange = (newView) => {
    setView(newView);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let base64String = atob(profile.fields.bookingData.value);
    try {
      setBookingData(JSON.parse(base64String));
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }, []);

  const appContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minHeight: "100vh",
    backgroundColor: colorScheme.systemGray5,
    position: "relative",
    zIndex: 1,
  };

  const navBarStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "94%",
    height: "50px",
    padding: "0% 3%",
    margin: "0px",
    backgroundColor: colorScheme.systemGray6,
    color: colorScheme.primary,
  };
  const tabContainerWidth = profile.fields.tabs.value.length * 90;
  const pickerContainerStyle = {
    display: "flex",
    justifyContent: "center",
    padding: "3px",
    margin: "0 auto",
    width: `${tabContainerWidth}px`,
    height: "25px",
    backgroundColor: colorScheme.systemGray6,
    borderRadius: "8px",
    gap: "4px",
  };

  const pickerButtonStyle = {
    fontSize: "14px",
    fontWeight: "600",
    border: "0px solid gray",
    backgroundColor: colorScheme.systemGray6,
    width: "90px",
    borderRadius: "6px",
    color: "gray",
  };

  const selectedButtonStyle = {
    ...pickerButtonStyle,
    color: colorScheme.primary,
    backgroundColor: colorScheme.systemGray5,
  };

  const contentContainerStyle = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: "20px",
    flexGrow: 1,
  };

  const footerStyle = {
    width: "100%",
    height: "auto", // or the height you want it to have.
    lineHeight: "60px",
    //backgroundColor: "#333",
    color: colorScheme.primary,
    position: "relative",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: "24px",
  };

  const customLinkContainer = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    gap: "0px 20px",
    flexWrap: "wrap",
  };

  const customLinkStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "3",
  };

  const socialLinkContainer = {
    position: "relative",
    display: "flex",
    gap: "20px",
  };

  const socialLinkStyle = {
    height: "30px",
    display: "flex",
    gap: "20px",
  };

  const copyrightStyle = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0",
  };

  const divider = {
    height: "100%",
    borderLeft: "1px solid lightgray",
  };

  function getDevice() {
    const userAgent = navigator.userAgent;
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );

    if (isMobile) {
      return "Mobile device";
    } else {
      return "Desktop device";
    }
    //return navigator.userAgent;
  }

  return (
    <div>
      <div className="App" style={appContainerStyle}>
        <div style={navBarStyle}>
          <h1 style={{ fontWeight: "900" }}>TattSafe</h1>
          <p>Nav Bar Trailing</p>
        </div>

        <Profile profile={profile} />

        <div style={pickerContainerStyle}>
          {profile.fields.tabs.value.includes(0) && (
            <>
              <button
                style={
                  view === "booking"
                    ? { ...pickerButtonStyle, ...selectedButtonStyle }
                    : pickerButtonStyle
                }
                onClick={() => handleViewChange("booking")}
              >
                Book
              </button>

              <div style={divider}></div>
            </>
          )}

          {profile.fields.tabs.value.includes(1) && (
            <>
              <button
                style={
                  view === "flash"
                    ? { ...pickerButtonStyle, ...selectedButtonStyle }
                    : pickerButtonStyle
                }
                onClick={() => handleViewChange("flash")}
              >
                Flash
              </button>
            </>
          )}

          {profile.fields.tabs.value.includes(1 || 2) && (
            <div style={divider}></div>
          )}

          {profile.fields.tabs.value.includes(2) && (
            <>
              <button
                style={
                  view === "portfolio"
                    ? { ...pickerButtonStyle, ...selectedButtonStyle }
                    : pickerButtonStyle
                }
                onClick={() => handleViewChange("portfolio")}
              >
                Portfolio
              </button>
            </>
          )}

          {profile.fields.tabs.value.includes(3) && (
            <>
              <div style={divider}></div>

              <button
                style={
                  view === "contact"
                    ? { ...pickerButtonStyle, ...selectedButtonStyle }
                    : pickerButtonStyle
                }
                onClick={() => handleViewChange("contact")}
              >
                Contact
              </button>
            </>
          )}
        </div>

        <div style={contentContainerStyle}>
          {view === "booking" ? (
            <Booking profile={profile} setSlot={setSlot} />
          ) : view === "flash" ? (
            <Flash
              profile={profile}
              flashData={flashData}
              setFlashData={setFlashData}
              flashIndex={flashIndex}
            />
          ) : view === "portfolio" ? (
            <Portfolio portfolioRecordNames={profile.fields.portfolio.value} />
          ) : (
            <Contact profile={profile} />
          )}
        </div>
        <footer style={footerStyle}>
          <div style={customLinkContainer}>
            {customLinks.map((link, index) => {
              const [title, url] = link.slice(1).split("*"); // Remove the leading asterisk before splitting

              return (
                <a
                  key={index}
                  href={url}
                  style={customLinkStyle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {title}
                </a>
              );
            })}
          </div>

          <div style={socialLinkContainer}>
            {socialLinks.map((link, index) => {
              const platform = getPlatformName(link);
              const logo = socialLogos[platform];

              return (
                <a
                  key={index}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={logo} alt={platform} style={socialLinkStyle} />
                </a>
              );
            })}
          </div>

          <p style={copyrightStyle}>
            © 2023 by TattSafe & {profile.fields.displayName.value}
          </p>
        </footer>
      </div>
    </div>
  );
}

export default Home;
