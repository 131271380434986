import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import FlashController from "./FlashController";

const Flash = ({ profile, flashData, setFlashData, flashIndex }) => {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    fetch("https://t1ov9ij1mh.execute-api.us-east-1.amazonaws.com/Prod", {
      method: "POST",
      body: JSON.stringify({ recordNames: profile.fields.flash.value }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        setFlashData(data.records);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  const handleSelection = (index) => {
    flashIndex(index);
  };

  const handleClose = () => {
    setSelectedIndex(null);
  };

  const contentContainerStyle = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexGrow: 1,
  };

  const galleryStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "10px",
    width: "calc(100% - 40px)",
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (flashData) {
    return (
      <div style={contentContainerStyle}>
        {flashData && selectedIndex && (
          <FlashController
            profile={profile}
            flashData={flashData}
            initialIndex={selectedIndex}
            handleClose={handleClose}
          />
        )}
        <div style={galleryStyle}>
          {flashData.map((flashRecord, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                paddingBottom: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
                onClick={() => handleSelection(index)}
              >
                <img
                  src={flashRecord.fields.image.value.downloadURL}
                  alt=""
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  ${flashRecord.fields.price.value}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return <p>No Bookable Flash Available</p>;
  }
};

export default Flash;
