import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import FlashCarousel from "./FlashCarousel";
import MobileFlashCarousel from "./MobileFlashCarousel";
import MobileFlashCalendar from "./MobileFlashCalendar";
import MobileFlashUserInfo from "./MobileFlashUserInfo";
import FlashSuccess from "./FlashSuccess";
import { ReactComponent as XMark } from "../assets/xmark.svg";

const FlashController = ({ profile, flashData, initialIndex, handleClose }) => {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFlash, setSelectedFlash] = useState(null);
  const [view, setView] = useState("carousel");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestResponse, setRequestResponse] = useState(null);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(null);
  const [request, setRequest] = useState({
    alertBody: "",
    colorSpace: "gray",
    date: "",
    deposit: 0,
    dob: null,
    duration: 0,
    email: "",
    endDate: null,
    familyName: "",
    flash: "",
    givenName: "",
    height: "",
    locationOnBody: "",
    notes: "",
    phone: "",
    profile: "",
    referenceImages: [],
    subtitle: "",
    startDate: null,
    title: "New Request",
    total: 0,
    type: "flash",
    width: "",
  });

  function mobile() {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    if (isMobile) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (isLoading && profile) {
      setRequest((prevData) => ({
        ...prevData,
        profile: profile.recordName,
      }));
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    console.log("success", success);
    if (success) {
      setView("success");
    }
  }, [success]);

  useEffect(() => {
    console.log("request", request);
  }, [request]);

  const toDetail = () => {
    //setOutgoingView(view);
    setView("carousel");
  };

  const setFlash = (flash) => {
    setSelectedFlash(flash);
  };

  const toCalendar = () => {
    let height;
    let locationOnBody;
    let width;
    if (selectedFlash.fields.height && selectedFlash.fields.height.value) {
      height = selectedFlash.fields.height.value;
    } else {
      height = 0;
    }
    if (
      selectedFlash.fields.locationOnBody &&
      selectedFlash.fields.locationOnBody.value
    ) {
      locationOnBody = selectedFlash.fields.locationOnBody.value;
    } else {
      locationOnBody = "";
    }
    if (selectedFlash.fields.width && selectedFlash.fields.width.value) {
      width = selectedFlash.fields.width.value;
    } else {
      width = 0;
    }
    setRequest((prevData) => ({
      ...prevData,
      deposit: selectedFlash.fields.deposit.value ?? 0,
      duration: selectedFlash.fields.duration.value,
      flash: selectedFlash.recordName,
      height: height,
      locationOnBody: locationOnBody,
      total: selectedFlash.fields.price.value,
      width: width,
    }));
    setView("calendar");
  };

  const toUserInfo = () => {
    let dateString = request.startDate.toLocaleDateString("en-us", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    setRequest((prevData) => ({
      ...prevData,
      date: request.startDate.toISOString(),
      subtitle: dateString,
    }));
    setView("userInfo");
  };

  const calculateAge = (dob, apptDate) => {
    let age = apptDate.getFullYear() - dob.getFullYear();
    const m = apptDate.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && apptDate.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  };

  const sendRequest = async (requestData, callback) => {
    const url = `https://usyu59afm2.execute-api.us-east-1.amazonaws.com/Prod`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      console.error("Response", data);
      if (response.status === 200) {
        callback(null, data);
        return;
      }

      callback(data.message, null);
    } catch (error) {
      console.error("Error sending request:", error);
      callback(error, null);
    }
  };

  const handleSubmit = async () => {
    console.log("handleSubmit called");
    if (isSubmitting) {
      console.log("Processing, please wait...");
      return;
    }

    if (request.dob) {
      const age = calculateAge(new Date(request.dob), request.startDate);
      if (age < 18) {
        alert(
          "You must be 18 years old at the time of the appointment to submit a request."
        );
        return;
      }
    } else {
      alert(
        "Please enter a Date of Birth.\n\nYou must be 18 years old at the time of the appointment to submit a request."
      );
      return;
    }

    if (request.givenName == "" || request.givenName.trim() === "") {
      alert("Please enter your first name.");
      return;
    } else if (request.familyName == "" || request.familyName.trim() === "") {
      alert("Please enter your last name.");
      return;
    } else if (
      !request.phone ||
      request.phone.trim() === "" ||
      request.phone.length != 14
    ) {
      alert("Please provide a valid phone number.");
      return;
    } else if (request.email == "" || request.email.trim() === "") {
      alert("Please enter your email address.");
      return;
    }

    setIsSubmitting(true);
    let currentRequest = request;
    currentRequest.alertBody = currentRequest.givenName;
    console.log("currentRequest.alertBody", currentRequest);
    await sendRequest(currentRequest, (error, response) => {
      if (error) {
        setFailure(error);
      }
      if (
        response &&
        response.records &&
        response.records[0] &&
        response.records[0].recordName
      ) {
        const record = response.records[0];
        setRequestResponse(record);
        setSuccess(true);
      }
      setIsSubmitting(false);
    });
  };

  const renderView = () => {
    switch (view) {
      case "carousel":
        if (mobile()) {
          return (
            <MobileFlashCarousel
              flashData={flashData}
              initialIndex={initialIndex}
              setFlash={setFlash}
              toCalendar={toCalendar}
            />
          );
        } else {
          return (
            <FlashCarousel
              flashData={flashData}
              initialIndex={initialIndex}
              setFlash={setFlash}
              toCalendar={toCalendar}
            />
          );
        }
      case "calendar":
        return (
          <MobileFlashCalendar
            bookingData={profile.fields.bookingData.value}
            flash={selectedFlash}
            request={request}
            setRequest={setRequest}
            toDetail={toDetail}
            toUserInfo={toUserInfo}
          />
        );
      case "userInfo":
        return (
          <MobileFlashUserInfo
            flash={selectedFlash}
            request={request}
            setRequest={setRequest}
            toCalendar={toCalendar}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        );
      case "success":
        return (
          <FlashSuccess
            requestResponse={requestResponse}
            handleClose={handleClose}
          />
        );
      default:
        return (
          <MobileFlashCarousel
            flashData={flashData}
            initialIndex={initialIndex}
            setFlash={setFlash}
            toCalendar={toCalendar}
            handleClose={handleClose}
          />
        );
    }
  };

  const bg = {
    zIndex: 2,
    position: "absolute",
    top: 0,
    display: "flex",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    border: "none",
    justifyContent: "center",
    alignItems: "flex-start",
  };

  const xmarkButton = {
    position: "absolute",
    top: "0",
    right: "0",
    padding: mobile() ? "8px" : "16px",
    margin: "0",
    border: "none",
    boxSizing: "0",
    backgroundColor: "rgba(0, 0, 0, 0.0)",
  };

  const xmark = {
    width: mobile() ? "32px" : "48px",
    height: mobile() ? "32px" : "48px",
    fill: "#fff",
  };

  return (
    <div style={bg}>
      <button style={xmarkButton} onClick={handleClose}>
        <XMark style={xmark} />
      </button>
      {renderView()}
    </div>
  );
};

export default FlashController;
