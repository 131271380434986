import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "./ThemeContext";

function ImageContainer({ image, parentWidth, borderRadius }) {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [greaterWidth, setGreaterWidth] = useState(false);
  const [imgWidth, setImgWidth] = useState(296);
  const [imgHeight, setImgHeight] = useState(296);

  useEffect(() => {
    setIsLoading(true);
    setGreaterWidth(false);
    setImgWidth(296);
    setImgHeight(296);

    const img = new Image();
    img.src = image;
    img.onload = () => {
      if (img.width > img.height) {
        setGreaterWidth(true);
        let imgRatio = img.height / img.width;
        setImgHeight(imgRatio * 296);
      } else if (img.width < img.height) {
        let imgRatio = img.width / img.height;
        setImgWidth(imgRatio * 296);
      }
      setIsLoading(false);
    };
  }, [image]);

  const imgStyle = {
    zIndex: 3,
    position: "relative",
    //top: 0,
    //left: 0,
    //width: greaterWidth ? "296px" : "auto",
    //height: greaterWidth ? "auto" : "296px",
    width: `${imgWidth}px`,
    height: `${imgHeight}px`,
    borderRadius: "16px",
  };

  const imgShadow = {
    zIndex: 3,
    position: "absolute",
    width: imgWidth,
    height: imgHeight,
    borderRadius: `${borderRadius}px`,
    boxShadow: "0px 0px 24px black",
    opacity: "0.4",
    backgroundColor: colorScheme.systemGray5,
  };

  const imgContainer = {
    zIndex: 1,
    position: "relative",
    //top: 0,
    //left: 0, //(window.innerWidth - 296) / 2,
    //width: `${parentWidth}px`,
    //height: `${parentWidth}px`,
    width: "296px",
    height: "296px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    // zIndex: 2,
    // position: "absolute",
    // top: 24,
    // left: (window.innerWidth - 296) / 2,
    // width: "296px",
    // height: "296px",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={imgContainer}>
        <div style={{ zIndex: 99, position: "absolute", top: 40, left: -140 }}>
          {imgWidth} x {imgHeight}
        </div>
        <div style={imgShadow}></div>
        <img style={imgStyle} src={image} alt="image" />
      </div>
    </div>
  );
}

export default ImageContainer;
