import React from "react";

function NoUserFound({ error }) {
  const style = {
    width: "100%",
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "2em",
  };

  return (
    <div>
      <h1 style={style}>No User Found</h1>
      {error && <h1 style={style}>Error: {error.message}</h1>}
    </div>
  );
}

export default NoUserFound;
