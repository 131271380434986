import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import { ReactComponent as ChevronLeft } from "../assets/chevron.left.svg";
import { ReactComponent as ChevronRight } from "../assets/chevron.right.svg";

function FlashCard({ flash, next, previous, toCalendar }) {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [greaterWidth, setGreaterWidth] = useState(false);
  const [imgWidth, setImgWidth] = useState(window.innerHeight * 0.88);
  const [imgHeight, setImgHeight] = useState(window.innerHeight * 0.88);

  useEffect(() => {
    setIsLoading(true);
    setGreaterWidth(false);
    setImgWidth(window.innerHeight * 0.88);
    setImgHeight(window.innerHeight * 0.88);

    const img = new Image();
    img.src = flash.fields.image.value.downloadURL;
    img.onload = () => {
      if (img.width > img.height) {
        setGreaterWidth(true);
        let imgRatio = img.height / img.width;
        setImgHeight(imgRatio * imgHeight);
      } else if (img.width < img.height) {
        let imgRatio = img.width / img.height;
        setImgWidth(imgRatio * imgWidth);
      }
      setIsLoading(false);
    };
  }, [flash]);

  function durationString() {
    const wholeHours = Math.floor(flash.fields.duration.value);
    const minutes = Math.round((flash.fields.duration.value - wholeHours) * 60);
    let result = "";
    if (wholeHours > 0) {
      result += wholeHours + (wholeHours === 1 ? " hour" : " hours");
    }
    if (minutes > 0) {
      if (result) result += " ";
      result += minutes + (minutes === 1 ? " minute" : " minutes");
    }
    return result || "0 minutes";
  }

  function size() {
    if (
      flash.fields.width &&
      flash.fields.width.value &&
      flash.fields.height &&
      flash.fields.height.value
    ) {
      let flashWidth = flash.fields.width.value;
      let flashHeight = flash.fields.height.value;
      return (
        <p style={{ fontSize: "16px", margin: "4px" }}>
          Size: {flashWidth}in x {flashHeight}in
        </p>
      );
    }
  }

  function locationOnBody() {
    if (flash.fields.locationOnBody && flash.fields.locationOnBody.value) {
      return (
        <p style={{ fontSize: "16px", margin: "4px" }}>
          Location: {flash.fields.locationOnBody.value}
        </p>
      );
    }
  }

  const imgContainer = {
    zIndex: 1,
    position: "absolute",
    top: "0",
    //left: "0",
    left: "48%",
    transform: "translateX(-48%)",
    width: "88vh",
    height: "88vh",
    width: window.innerWidth > window.innerHeight ? "88vh" : "88vw",
    height: window.innerWidth > window.innerHeight ? "88vh" : "88vw",
    maxWidth: "88vw",
    maxHeight: "88vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "4vh 4vw",
  };

  const imgStyle = {
    zIndex: 2,
    position: "relative",
    width: greaterWidth ? "80vh" : "auto",
    height: greaterWidth ? "auto" : "80vh",
    maxWidth: "80vw",
    maxHeight: "80vw",
    borderRadius: "1vw",
    boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.4)",
  };

  const infoContainer = {
    zIndex: 2,
    position: "absolute",
    right: "0",
    bottom: "0",
    width: "24%",
    minWidth: "256px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "1vh",
    marginRight: "4vh",
    marginBottom: "8vh",
    borderRadius: "1vw",
    boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.systemGray6,
  };
  const info = {
    zIndex: 3,
    fontWeight: "500",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const buttonContainer = {
    width: "88%",
    height: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    //backgroundColor: "pink",
  };

  const arrowButtonStyle = {
    zIndex: 4,
    position: "relative",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    color: "white",
    top: "16px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  const bookButtonStyle = {
    zIndex: 4,
    position: "relative",
    width: "96px",
    height: "40px",
    fontSize: "16px",
    color: "white",
    top: "16px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={imgContainer}>
        <img
          style={imgStyle}
          src={flash.fields.image.value.downloadURL}
          alt=""
        />
      </div>
      <div style={infoContainer}>
        <div style={info}>
          <p style={{ fontSize: "16px", margin: "4px" }}>{durationString()}</p>

          <p style={{ fontSize: "16px", margin: "4px" }}>
            Deposit ${flash.fields.deposit.value} / ${flash.fields.price.value}
          </p>

          {size()}

          {locationOnBody()}
        </div>

        <div style={buttonContainer}>
          <button style={arrowButtonStyle} onClick={() => previous()}>
            <ChevronLeft width="24px" height="24px" fill="#fff" />
          </button>

          <button style={bookButtonStyle} onClick={() => toCalendar()}>
            Book
          </button>

          <button style={arrowButtonStyle} onClick={() => next()}>
            <ChevronRight width="24px" height="24px" fill="#fff" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default FlashCard;
