import React, { useState, useEffect, useContext, useRef } from "react";
import { ThemeContext } from "../ThemeContext";

import InstagramLogo from "../assets/instagram-sm.png";
import TikTokLogo from "../assets/tiktok-sm.png";
import XLogo from "../assets/x-sm.png";
import TwitterLogo from "../assets/twitter-sm.png";
import YouTubeLogo from "../assets/youtube-sm.png";
import FacebookLogo from "../assets/facebook-sm.png";
import OnlyfansLogo from "../assets/onlyfans-sm.png";

function Contact({ profile }) {
  const { colorScheme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState(null);
  const [error, setError] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const recordNames = profile.fields.locations.value;

  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const mapKitToken =
    "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjZBTFRXOVpZU1EifQ.eyJpc3MiOiI3NjhXNlFUM1Y3IiwiaWF0IjoxNjkwNzMwMzM3LCJleHAiOjE3MjIyMTEyMDB9.7nIxD-Be95iZqipAnej_w3IEnntkA_JVkVRfrzD43ttGbcIyX0oXBl7v09aiy7OeiK87YKoTjSVeOi8VIAt85A";

  useEffect(() => {
    fetch("https://jg1gr88ope.execute-api.us-east-1.amazonaws.com/Prod", {
      method: "POST",
      body: JSON.stringify({
        recordNames: recordNames,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        setLocations(data.records);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (locations && !map && window.mapkit) {
      initializeMapKit(locations);
    }
  }, [locations]);

  const initializeMapKit = (locations) => {
    if (!map && window.mapkit) {
      window.mapkit.init({
        authorizationCallback: function (done) {
          done(mapKitToken);
        },
      });

      const map = new window.mapkit.Map(mapContainerRef.current);

      if (locations.length > 0) {
        const latitudes = locations.map(
          (location) => location.fields.latitude.value
        );
        const longitudes = locations.map(
          (location) => location.fields.longitude.value
        );
        const minLatitude = Math.min(...latitudes);
        const maxLatitude = Math.max(...latitudes);
        const minLongitude = Math.min(...longitudes);
        const maxLongitude = Math.max(...longitudes);

        locations.forEach((location) => {
          const marker = new window.mapkit.MarkerAnnotation(
            new window.mapkit.Coordinate(
              location.fields.latitude.value,
              location.fields.longitude.value
            )
          );
          map.addAnnotation(marker);
        });

        const center = new window.mapkit.Coordinate(
          (minLatitude + maxLatitude) / 2,
          (minLongitude + maxLongitude) / 2
        );

        let latSpan, lonSpan;

        if (locations.length > 1) {
          // add a 10% buffer to the span
          latSpan = 1.1 * (maxLatitude - minLatitude);
          lonSpan = 1.1 * (maxLongitude - minLongitude);
        } else {
          // Set default span for a single location
          latSpan = 0.005;
          lonSpan = 0.005;
        }

        const span = new window.mapkit.CoordinateSpan(latSpan, lonSpan);
        const region = new window.mapkit.CoordinateRegion(center, span);

        map.region = region;
      }

      setMap(map);
    }
  };

  const socialLogos = {
    instagram: InstagramLogo,
    tiktok: TikTokLogo,
    x: XLogo,
    twitter: TwitterLogo,
    youtube: YouTubeLogo,
    facebook: FacebookLogo,
    onlyfans: OnlyfansLogo,
  };

  function getPlatformName(url) {
    const urlObj = new URL(url);
    const platform = urlObj.hostname.split(".")[1];
    return platform;
  }

  const socialLinks = profile.fields.links.value.filter(
    (link) => !link.startsWith("*") && socialLogos[getPlatformName(link)]
  );

  const customLinks = profile.fields.links.value.filter((link) =>
    link.startsWith("*")
  );

  const getPlatform = (link) => {
    let urlObj = new URL(link);
    let platform = urlObj.hostname.split(".")[1];
    let uppercasePlatform = platform.toUpperCase();
    return uppercasePlatform;
  };

  const getUsername = (link) => {
    let parts = link.split("/");
    let username = parts[parts.length - 1];
    if (link.includes("instagram.com") || link.includes("x.com")) {
      return `@${username}`;
    }
    return username;
  };

  const container = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    //backgroundColor: "pink",
  };

  const bioStyle = {
    maxWidth: "800px",
    fontWeight: "normal",
    margin: "0px 24px",
    color: colorScheme.primary,
  };

  const flexContainer = {
    display: "flex",
    flexDirection: windowSize.width > 800 ? "row" : "column",
    gap: "24px",
  };

  const labelStyle = {
    fontSize: "24px",
    fontWeight: "800",
    margin: "4px 16px",
    color: colorScheme.primary,
  };

  const linksContainer = {
    width: "360px",

    //flexDirection: "column",
    //justifyContent: "center",
  };

  const linksList = {
    width: "360px",
    height: "flex",

    //flexDirection: "column",
    //justifyContent: "center",
    borderRadius: "8px",
    backgroundColor: colorScheme.systemGray6,
  };

  const linkStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
    //gap: "8px",
    //borderRadius: "8px",
    //backgroundColor: "red",
  };

  const locationContainer = {
    width: "360px",
  };

  const locationList = {
    width: "360px",
    //justifyContent: "center",
    paddingBottom: "2px",
    borderRadius: "8px",
    backgroundColor: colorScheme.systemGray6,
  };

  const locationStyle = {
    width: "328px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "8px 16px",
    borderBottom: "1px solid lightgray",
    //backgroundColor: "yellow",
  };

  const mapKitStyle = {
    width: "344px",
    height: "256px",
    margin: "8px",
    borderRadius: "8px",
    overflow: "clip",
  };

  const pStyle = {
    margin: "0px",
  };

  const divider = {
    height: "100%",
    borderTop: "1px solid lightgray",
    margin: "20px 0px",
  };

  const customLinkContainer = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    gap: "0px 20px",
    flexWrap: "wrap",
  };

  const customLinkStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "3",
  };

  return (
    <div style={container}>
      <p style={bioStyle}>{profile.fields.bio.value}</p>
      <div style={flexContainer}>
        <div style={linksContainer}>
          <div style={labelStyle}>Links</div>
          <div style={linksList}>
            {socialLinks.map((link, index) => {
              const platform = getPlatformName(link);
              const logo = socialLogos[platform];

              return (
                <a
                  key={index}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      ...linkStyle,
                      borderBottom:
                        link != socialLinks[socialLinks.length - 1]
                          ? "1px solid lightgray"
                          : "none",
                    }}
                  >
                    <img src={logo} alt={platform} style={{ height: "16px" }} />

                    <div>{getUsername(link)}</div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>

        <div style={locationContainer}>
          <div style={labelStyle}>
            {!loading && locations.length > 1 ? "Locations" : "Location"}
          </div>
          <div style={locationList}>
            {!loading && locations ? (
              locations.map((location, index) => {
                const field = location.fields;
                const name = field.name.value;
                const street = field.street.value;
                const city = field.city.value;
                const state = field.state.value;
                const postalCode = field.postalCode.value;
                const phone = field.phone.value;
                const email = field.email.value;
                const website = field.website.value;
                const latitude = field.latitude.value;
                const longitude = field.longitude.value;

                return (
                  <div key={index} style={locationStyle}>
                    <b>{name}</b>
                    <p style={pStyle}>{street}</p>
                    <p style={pStyle}>{`${city}, ${state} ${postalCode}`}</p>
                    <a
                      href={`${website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {website}
                    </a>
                  </div>
                );
              })
            ) : (
              <p>Loading...</p>
            )}
            <div ref={mapContainerRef} style={mapKitStyle}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
