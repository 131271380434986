import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../ThemeContext";
import { ReactComponent as XMark } from "../assets/xmark.svg";
import { ReactComponent as ChevronLeft } from "../assets/chevron.left.svg";
import { ReactComponent as ChevronRight } from "../assets/chevron.right.svg";

function ImageCarousel({ portfolio, initialIndex, onClose }) {
  const { colorScheme } = useContext(ThemeContext);
  const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % portfolio.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + portfolio.length - 1) % portfolio.length
    );
  };

  const handleClose = () => {
    onClose();
  };

  const containerStyle = {
    position: "absolute",
    right: "0",
    top: "0",
    padding: "8px",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    margin: "10px",
    border: "0",
    //width: "100%",
    //justifyContent: "center",
    //backgroundColor: "yellow",
  };

  const chevronButtonStyle = {
    position: "absolute",
    padding: "10px",
    paddingTop: "7px",
    paddingBottom: "5px",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    border: "0",
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: `${windowHeight}px`,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90%",
          position: "relative",
        }}
      >
        <button
          style={{
            ...containerStyle,
            marginRight: "20px",
            paddingBottom: "5px",
            paddingRight: "7px",
          }}
          onClick={handleClose}
        >
          <XMark width="30px" height="30px" fill="#fff" />
        </button>

        <button
          style={{
            ...chevronButtonStyle,
            left: "0",
            marginLeft: "10px",
          }}
          onClick={handlePreviousImage}
        >
          <ChevronLeft width="30px" height="60px" fill="#fff" />
        </button>
        <img
          src={portfolio[currentImageIndex].fields.after.value.downloadURL}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          alt="carousel"
        />
        <button
          style={{
            ...chevronButtonStyle,
            right: "0",
            marginRight: "10px",
          }}
          onClick={handleNextImage}
        >
          <ChevronRight width="30px" height="60px" fill="white" />
        </button>
      </div>
    </div>
  );
}

export default ImageCarousel;
