import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import { ReactComponent as ChevronLeft } from "../assets/chevron.left.svg";

function MobileFlashUserInfo({
  flash,
  request,
  setRequest,
  toCalendar,
  handleSubmit,
  isSubmitting,
}) {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [greaterWidth, setGreaterWidth] = useState(false);
  const [dobValue, setDOBValue] = useState(null);

  let startDate = request.startDate.toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
  });
  let endTime = request.endDate.toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "2-digit",
  });

  useEffect(() => {
    const img = new Image();
    img.src = flash.fields.image.value.downloadURL;
    img.onload = () => {
      if (img.width > img.height) {
        setGreaterWidth(true);
      }
    };
    setIsLoading(false);
  }, []);

  const updateRequest = (key, newValue) => {
    setRequest((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const handleDOB = (newDOB) => {
    setDOBValue(newDOB);
    let dobString;
    if (newDOB !== "") {
      const [year, month, day] = newDOB.split("-").map(Number);
      let dobDate = new Date(year, month - 1, day);
      dobString = dobDate.toISOString();
    }
    updateRequest("dob", dobString);
  };

  function durationString() {
    const wholeHours = Math.floor(flash.fields.duration.value);
    const minutes = Math.round((flash.fields.duration.value - wholeHours) * 60);

    let result = "";

    if (wholeHours > 0) {
      result += wholeHours + (wholeHours === 1 ? " hour" : " hours");
    }

    if (minutes > 0) {
      if (result) result += " ";
      result += minutes + (minutes === 1 ? " minute" : " minutes");
    }

    return result || "0 minutes";
  }

  function size() {
    if (
      flash.fields.width &&
      flash.fields.width.value &&
      flash.fields.height &&
      flash.fields.height.value
    ) {
      let flashWidth = flash.fields.width.value;
      let flashHeight = flash.fields.height.value;
      return (
        <p style={{ fontSize: "16px", margin: "0px" }}>
          Size: {flashWidth}in x {flashHeight}in
        </p>
      );
    }
  }

  function locationOnBody() {
    if (flash.fields.locationOnBody && flash.fields.locationOnBody.value) {
      return (
        <p style={{ fontSize: "16px", margin: "0px" }}>
          Location: {flash.fields.locationOnBody.value}
        </p>
      );
    }
  }

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    if (value.length > 3 && value.length <= 6)
      value = `(${value.substring(0, 3)}) ${value.substring(3)}`;
    else if (value.length > 6)
      value = `(${value.substring(0, 3)}) ${value.substring(
        3,
        6
      )}-${value.substring(6)}`;
    updateRequest("phone", value);
  };

  const imgStyle = {
    position: "relative",
    width: greaterWidth ? "88px" : "auto",
    height: greaterWidth ? "auto" : "88px",
    borderRadius: "8px",
    //backgroundColor: "pink",
  };

  const imgContainer = {
    width: "88px",
    height: "88px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "4px",
    //backgroundColor: "green",
  };

  const cardContainer = {
    zIndex: 3,
    position: "absolute",
    top: "24px",
    left: (window.innerWidth - 296) / 2,
    width: "296px",
    height: "96px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.systemGray6,
  };

  const infoStyle = {
    display: "flex",
    flexDirection: "column",
    opacity: "0.8",
    marginLeft: "8px",
  };

  const container = {
    zIndex: 1,
    position: "absolute",
    top: "72px",
    left: (window.innerWidth - 360) / 2,
    width: "360px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "16px",
    backgroundColor: colorScheme.systemGray6,
  };

  const dateTimeStyle = {
    fontSize: "8px",
    fontWeight: "500",
    padding: "4px 8px",
    borderRadius: "4px",
    backgroundColor: colorScheme.systemGray5,
  };

  const fieldContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    //backgroundColor: "yellow",
  };

  const labelStyle = {
    fontSize: "8px",
    fontWeight: "600",
    marginLeft: "8px",
    marginTop: "8px",
    marginBottom: "2px",
    color: "gray",
    //backgroundColor: "red",
  };

  const inputStyle = {
    width: "248px",
    fontSize: "16px",
    border: "1px solid gray",
    borderRadius: "4px",
    backgroundColor: colorScheme.systemGray5,
  };

  const charactersRemainingStyle = {
    fontSize: "8px",
    color: "gray",
    marginLeft: "8px",
    marginTop: "2px",
    //backgroundColor: "purple",
  };

  const dropStyle = {
    height: "20px",
    width: "100px",
    marginLeft: "10px",
    border: "none",
    borderRadius: "2px",
    backgroundColor: colorScheme.systemGray5,
  };

  const buttonContainer = {
    width: "312px",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    //backgroundColor: "pink",
  };

  const arrowButtonStyle = {
    zIndex: 1,
    position: "relative",
    width: "48px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    color: "white",
    top: "24px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  const bookButtonStyle = {
    zIndex: 2,
    position: "relative",
    width: "96px",
    height: "40px",
    fontSize: "16px",
    color: "white",
    top: "24px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  const spinner = {
    border: "4px solid rgba(255, 255, 255, 0.3)",
    borderRadius: "50%",
    borderTop: "4px solid white",
    width: "16px",
    height: "16px",
    animation: "spin 1s linear infinite",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const styles = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  `;
  document.head.insertAdjacentHTML("beforeend", `<style>${styles}</style>`);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={cardContainer}>
        <div style={imgContainer}>
          <img
            style={imgStyle}
            src={flash.fields.image.value.downloadURL}
            alt=""
          />
        </div>
        <div style={infoStyle}>
          <p style={{ fontSize: "16px", margin: "0px" }}>{durationString()}</p>

          <p style={{ fontSize: "16px", margin: "0px" }}>
            Deposit ${flash.fields.deposit.value} / ${flash.fields.price.value}
          </p>
          {size()}
          {locationOnBody()}
        </div>
      </div>

      <div style={container}>
        <div style={{ height: "64px" }}></div>
        <div style={dateTimeStyle}>
          {startDate} - {endTime}
        </div>
        <div style={{ textAlign: "center" }}>
          <div style={fieldContainerStyle}>
            <label style={labelStyle} htmlFor="date of birth">
              Date of Birth
            </label>
            <input
              value={dobValue}
              onChange={(e) => handleDOB(e.target.value)}
              style={inputStyle}
              type="date"
              id="dob"
              name="dob"
            />
          </div>

          <div style={fieldContainerStyle}>
            <label style={labelStyle} htmlFor="givenName">
              First Name
            </label>
            <input
              value={request.givenName}
              onChange={(e) => updateRequest("givenName", e.target.value)}
              style={inputStyle}
              type="text"
              id="givenName"
              name="givenName"
            />
          </div>

          <div style={fieldContainerStyle}>
            <label style={labelStyle} htmlFor="familyName">
              Last Name
            </label>
            <input
              value={request.familyName}
              onChange={(e) => updateRequest("familyName", e.target.value)}
              style={inputStyle}
              type="text"
              id="familyName"
              name="familyName"
            />
          </div>

          <div style={fieldContainerStyle}>
            <label style={labelStyle} htmlFor="tel">
              Phone
            </label>
            <input
              value={request.phone}
              onChange={handlePhoneChange}
              style={inputStyle}
              type="text"
              id="phone"
              name="phone"
              maxLength="14"
            />
          </div>

          <div style={fieldContainerStyle}>
            <label style={labelStyle} htmlFor="email">
              Email
            </label>
            <input
              value={request.email}
              onChange={(e) => updateRequest("email", e.target.value)}
              style={inputStyle}
              type="email"
              id="email"
              name="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
          </div>

          <div style={fieldContainerStyle}>
            <label style={labelStyle} htmlFor="email">
              Notes for the Artist
            </label>
            <textarea
              value={request.notes}
              onChange={(e) => updateRequest("notes", e.target.value)}
              maxLength={256}
              style={{ ...inputStyle, height: "88px" }}
              placeholder="size... location on body... colors..."
            />
            <div style={charactersRemainingStyle}>
              {256 - request.notes.length} characters remaining
            </div>
          </div>
        </div>

        <div style={buttonContainer}>
          <button style={arrowButtonStyle} onClick={() => toCalendar()}>
            <ChevronLeft width="24px" height="24px" fill="#fff" />
          </button>

          <button style={bookButtonStyle} onClick={() => handleSubmit()}>
            {isSubmitting ? <div style={spinner}></div> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobileFlashUserInfo;
