import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "./ThemeContext";

function Data() {
  const { darkMode, setDarkMode, colorScheme } = useContext(ThemeContext);
  const [showRequestCover, setShowRequestCover] = useState(true);
  const [requestLocation, setRequestLocation] = useState(true);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (navigator.geolocation && requestLocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation(position);
      });
      console.log(location);
    }
  }, []);

  function getSubdomain(url) {
    const urlObj = new URL(url);
    const subdomain = urlObj.hostname.split(".")[1]; // This assumes URLs are in the format "www.platform.com"
    return subdomain;
  }

  const userAgent = navigator.userAgent;
  function getDevice() {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (isMobile) {
      return "Mobile device";
    } else {
      return "Desktop device";
    }
  }

  const bg = {
    display: "flex",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    border: "none",
    paddingLeft: "20px",
    fontFamily: '"Courier New", "Courier", monospace',
    color: "green",
  };

  return (
    <div style={bg}>
      <div>
        <p style={{ marginBottom: "0" }}>{userAgent}</p>
        <p style={{ marginTop: "0" }}>{getDevice()}</p>

        <p style={{ marginBottom: "0" }}>Width: {windowSize.width}</p>
        <p style={{ marginTop: "0" }}>Height: {windowSize.height}</p>

        {location ? (
          <div>
            <p style={{ margin: "0" }}>Latitude: {location.coords.latitude}</p>
            <p style={{ margin: "0" }}>
              Longitude: {location.coords.longitude}
            </p>
            <p style={{ margin: "0" }}>
              Accuracy: {location.coords.accuracy} meters
            </p>
            <p style={{ margin: "0" }}>
              Heading: {location.coords.heading ?? "N/A"} degrees
            </p>
            <p style={{ margin: "0" }}>
              Speed: {location.coords.speed ?? "N/A"} meters/second
            </p>
            <p style={{ margin: "0" }}>
              Floor: {location.coords.floorLevel ?? "N/A"}
            </p>
            <p style={{ margin: "0" }}>
              Altitude: {location.coords.altitude ?? "N/A"} meters
            </p>
            <p style={{ margin: "0" }}>
              Altitude Accuracy: {location.coords.altitudeAccuracy ?? "N/A"}{" "}
              meters
            </p>
          </div>
        ) : (
          <p>No Geo Data</p>
        )}
      </div>
    </div>
  );
}

export default Data;
