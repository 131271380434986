import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import { ReactComponent as ChevronLeft } from "../assets/chevron.left.svg";
import { ReactComponent as ChevronRight } from "../assets/chevron.right.svg";

function MobileFlashCard({ flash, next, previous, toCalendar }) {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [greaterWidth, setGreaterWidth] = useState(false);
  const [imgWidth, setImgWidth] = useState(296);
  const [imgHeight, setImgHeight] = useState(296);

  const [currentImage, setCurrentImage] = useState(
    flash.fields.image.value.downloadURL
  );
  const [nextImage, setNextImage] = useState("");

  useEffect(() => {
    setIsLoading(true);
    setGreaterWidth(false);
    setImgWidth(296);
    setImgHeight(296);

    const img = new Image();
    img.src = flash.fields.image.value.downloadURL;
    img.onload = () => {
      setNextImage(flash.fields.image.value.downloadURL);
      if (img.width > img.height) {
        setGreaterWidth(true);
        let imgRatio = img.height / img.width;
        setImgHeight(imgRatio * 296);
      } else if (img.width < img.height) {
        let imgRatio = img.width / img.height;
        setImgWidth(imgRatio * 296);
      }
      setIsLoading(false);
    };
  }, [flash]);

  function durationString() {
    const wholeHours = Math.floor(flash.fields.duration.value);
    const minutes = Math.round((flash.fields.duration.value - wholeHours) * 60);
    let result = "";
    if (wholeHours > 0) {
      result += wholeHours + (wholeHours === 1 ? " hour" : " hours");
    }
    if (minutes > 0) {
      if (result) result += " ";
      result += minutes + (minutes === 1 ? " minute" : " minutes");
    }
    return result || "0 minutes";
  }

  function size() {
    if (
      flash.fields.width &&
      flash.fields.width.value &&
      flash.fields.height &&
      flash.fields.height.value
    ) {
      let flashWidth = flash.fields.width.value;
      let flashHeight = flash.fields.height.value;
      return (
        <p style={{ fontSize: "16px", margin: "4px" }}>
          Size: {flashWidth}in x {flashHeight}in
        </p>
      );
    }
  }

  function locationOnBody() {
    if (flash.fields.locationOnBody && flash.fields.locationOnBody.value) {
      return (
        <p style={{ fontSize: "16px", margin: "4px" }}>
          Location: {flash.fields.locationOnBody.value}
        </p>
      );
    }
  }

  const container = {
    zIndex: 1,
    position: "absolute",
    top: 128,
    left: (window.innerWidth - 360) / 2,
    width: "360px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "8vmin",
    borderRadius: "3vmin",
    backgroundColor: colorScheme.systemGray5,
  };

  const imgContainer = {
    zIndex: 2,
    position: "absolute",
    top: 24,
    left: (window.innerWidth - 296) / 2,
    width: "296px",
    height: "296px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const imgStyle = {
    zIndex: 4,
    position: "relative",
    width: greaterWidth ? "296px" : "auto",
    height: greaterWidth ? "auto" : "296px",
    borderRadius: "16px",
    boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.4)",
  };

  const buttonContainer = {
    width: "312px",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    //backgroundColor: "pink",
  };

  const arrowButtonStyle = {
    zIndex: 1,
    position: "relative",
    width: "48px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    color: "white",
    top: "24px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  const bookButtonStyle = {
    zIndex: 2,
    position: "relative",
    width: "96px",
    height: "40px",
    fontSize: "16px",
    color: "white",
    top: "24px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={imgContainer}>
        <img
          //className={animationClass}
          style={imgStyle}
          src={flash.fields.image.value.downloadURL}
          alt=""
        />
      </div>

      <div style={container}>
        <div style={{ height: "176px" }}></div>
        <div style={{ textAlign: "center", fontWeight: "500", height: "88px" }}>
          <p style={{ fontSize: "16px", margin: "4px" }}>{durationString()}</p>

          <p style={{ fontSize: "16px", margin: "4px" }}>
            Deposit ${flash.fields.deposit.value} / ${flash.fields.price.value}
          </p>

          {size()}

          {locationOnBody()}
        </div>

        <div style={buttonContainer}>
          <button style={arrowButtonStyle} onClick={() => previous()}>
            <ChevronLeft width="24px" height="24px" fill="#fff" />
          </button>

          <button style={bookButtonStyle} onClick={() => toCalendar()}>
            Book
          </button>

          <button style={arrowButtonStyle} onClick={() => next()}>
            <ChevronRight width="24px" height="24px" fill="#fff" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobileFlashCard;
