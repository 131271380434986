import React, { useState, useEffect } from "react";
import { ThemeProvider } from "./ThemeProvider";
import AppView from "./AppView.js";
import NoUserFound from "./NoUserFound";

function App() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [username, setUsername] = useState("User");
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const hostnameArray = window.location.hostname.split(".");
    const subdomain = hostnameArray[0];
    if (subdomain && subdomain !== "www") {
      setUsername(subdomain);
    }

    fetch("https://7z40jyy8wd.execute-api.us-east-1.amazonaws.com/Prod", {
      //fetch("https://fetch-profile.tattsafe.workers.dev", {
      method: "POST",
      // localhost
      //body: JSON.stringify({ username: "motophoto" }),
      // production
      body: JSON.stringify({ username: username }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        console.log(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [username]);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <NoUserFound error={error} />;
  }

  if (username && username == "applinks") {
    return (
      <div>
        <pre>
          {JSON.stringify(
            {
              applinks: {
                apps: [],
                details: [
                  {
                    appID: "768W6QT3V7.com.chrispecktattoos.TattSafeMK8",
                    paths: ["*"],
                  },
                  {
                    appID: "768W6QT3V7.com.flyinbuffalo.autoXPost",
                    paths: ["*"],
                  },
                ],
              },
            },
            null,
            2
          )}
        </pre>
      </div>
    );
  } else if (data && data.records && data.records.length > 0) {
    return (
      <ThemeProvider>
        <AppView profile={data.records[0]} />
        {/* <div>
          <div style={{ position: "fixed", zIndex: 2 }}>
            
          </div>
          <div style={{ zIndex: 1 }}>
            <Home profile={data.records[0]} />
          </div>
        </div> */}
      </ThemeProvider>
    );
  } else {
    return <NoUserFound />;
  }
}

export default App;
