import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import Calendar from "../Calendar/Calendar";
import BookingController from "./BookingController";
import BookingRow from "./BookingRow";

function Booking({ profile, setSlot }) {
  const { colorScheme } = useContext(ThemeContext);
  const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const setDate = (date) => {
    setSelectedDate(date);
  };
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  const selectedDateString = selectedDate.toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  useEffect(() => {
    let base64String = atob(profile.fields.bookingData.value);
    let bookingData;
    try {
      bookingData = JSON.parse(base64String);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
    // Convert all dates in the bookings into JavaScript dates at the beginning of the day
    const slotsWithDates = bookingData.availableSlots.map((slot) => ({
      ...slot,
      //original_date_start: slot.startTime, // hold the original Unix timestamp
      start_of_day: new Date(slot.startString).setHours(0, 0, 0, 0), // convert to JavaScript Date
    }));

    setSlots(slotsWithDates);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedDate !== null) {
      const selectedDateStartOfDay = selectedDate.setHours(0, 0, 0, 0);
      const selectedDaySlots = slots.filter(
        (slot) => slot.start_of_day === selectedDateStartOfDay
      );
      setSelectedSlots(selectedDaySlots);
    } else {
      setSelectedSlots([]);
    }
  }, [selectedDate, slots, date]);

  const container = {
    width: "100%",
    display: "flex",
    flexDirection: windowSize.width > 700 ? "row" : "column",
    alignItems: windowSize.width > 700 ? "flex-start" : "center",
    justifyContent: "center",
    //backgroundColor: "pink",
  };

  const calendarContainer = {
    display: "flex",
    justifyContent: "center",
    //backgroundColor: "pink",
  };

  const subContainer = {
    width: "360px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
    //backgroundColor: "pink",
  };

  const noSlotStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorScheme.systemGray6,
    borderRadius: "8px",
    padding: "8px",
    color: "gray",
  };

  return (
    <div style={container}>
      <div style={calendarContainer}>
        <Calendar
          slots={slots}
          selectedDate={selectedDate}
          setSelectedDate={setDate}
        />
      </div>
      <div style={subContainer}>
        <p style={{ fontWeight: "bold", fontSize: "16px", marginTop: "0px" }}>
          {selectedDateString}
        </p>
        {selectedDate !== null && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {selectedSlots.length > 0 ? (
              selectedSlots.map((slot, index) => (
                <button
                  onClick={() => setSlot(slot)}
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.0)",
                    border: "none",
                  }}
                >
                  <BookingRow key={index} slot={slot} />
                </button>
              ))
            ) : (
              <div style={noSlotStyle}>No availability on this day.</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Booking;
