import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import { ReactComponent as ChevronLeft } from "../assets/chevron.left.svg";
import { ReactComponent as ChevronRight } from "../assets/chevron.right.svg";

function Calendar({ range, slots, selectedDate, setSelectedDate }) {
  const { colorScheme } = useContext(ThemeContext);
  const [date, setDate] = useState(selectedDate);
  const [selectedDay, setSelectedDay] = useState(selectedDate.getDate());
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const month = date.getMonth();
  const year = date.getFullYear();
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const firstDay = new Date(year, month, 1).getDay();
  const daysInMonth =
    32 - new Date(date.getFullYear(), date.getMonth(), 32).getDate();
  let dayIndicator = hexToRGBA(colorScheme.accent, 0.2);

  useEffect(() => {
    const currentDate = new Date();
    if (
      currentDate.getMonth() === month &&
      currentDate.getFullYear() === year
    ) {
      setSelectedDay(currentDate.getDate());
    }
  }, [month, year]);

  useEffect(() => {
    const dateSelected = new Date(year, month, selectedDay);
    setSelectedDate(dateSelected);
  }, [selectedDay]);

  const daysWithSlots = new Set(
    slots
      .filter((slot) => {
        const slotDate = new Date(slot.start_of_day);
        return slotDate.getFullYear() === year && slotDate.getMonth() === month;
      })
      .map((slot) => new Date(slot.start_of_day).getDate())
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePrevMonth = () => {
    setDate(new Date(year, month - 1));
    setSelectedDate(new Date(year, month - 1, selectedDate.getDate()));
  };

  const handleNextMonth = () => {
    setDate(new Date(year, month + 1));
    setSelectedDate(new Date(year, month + 1, selectedDate.getDate()));
  };

  let cells = [];
  for (let i = 0; i < firstDay; i++) {
    cells.push(<div className="calendar-day empty"></div>);
  }
  for (let i = 1; i <= daysInMonth; i++) {
    const hasSlot = daysWithSlots.has(i);
    const isToday =
      new Date().getDate() === i &&
      new Date().getMonth() === month &&
      new Date().getFullYear() === year;
    cells.push(
      <div
        key={i}
        className={`calendar-day ${hasSlot ? "has-slot" : ""} ${
          i === selectedDay ? "selected" : ""
        } ${isToday ? "today" : ""}`}
        onClick={() => {
          const fullDate = new Date(year, month, i);
          setSelectedDay(i);
        }}
      >
        <span
          style={{
            fontSize: "16px",
            fontWeight: hasSlot ? "bold" : "normal",
            color: hasSlot ? colorScheme.accent : "gray",
          }}
        >
          {i}
        </span>
      </div>
    );
  }

  function hexToRGBA(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  const calendarStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "256px",
    height: "264px",
  };

  const calendarNavigation = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1vmin",
    fontWeight: "bold",
    fontSize: "16px",
    color: colorScheme.primary,
  };

  const monthButtonStyle = {
    width: "32px",
    border: "0px",
    backgroundColor: "rgba(0, 0, 0, 0.0)",
  };

  const daysGridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
  };

  const daysCellStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    color: colorScheme.primary,
    fontSize: "12px",
  };

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
  };

  const cellStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    aspectRatio: "1",
    backgroundColor: "",
    position: "relative",
    cursor: "pointer",
    color: colorScheme.primary,
    borderRadius: "8px",
  };

  return (
    <div style={calendarStyle}>
      <div style={calendarNavigation}>
        <span>
          {`${date.toLocaleString("default", {
            month: "long",
          })} ${year}`}
        </span>
        <div>
          <button style={monthButtonStyle} onClick={handlePrevMonth}>
            <ChevronLeft
              height="16px"
              fill={colorScheme.accent}
              aria-label="previous"
            />
          </button>
          <button style={monthButtonStyle} onClick={handleNextMonth}>
            <ChevronRight
              height="16px"
              fill={colorScheme.accent}
              aria-label="next"
            />
          </button>
        </div>
      </div>
      <div className="calendar-header" style={daysGridStyle}>
        {days.map((day, index) => (
          <div key={index} className="calendar-day" style={daysCellStyle}>
            {day}
          </div>
        ))}
      </div>
      <div className="calendar-body" style={gridStyle}>
        {cells.map((cell, index) =>
          React.cloneElement(cell, {
            key: index,
            style: cell.props.className.includes("selected")
              ? {
                  ...cellStyle,
                  border: "2px solid #007AFF",
                  boxSizing: "border-box",
                  backgroundColor: cell.props.className.includes("today")
                    ? dayIndicator
                    : "",
                }
              : {
                  ...cellStyle,
                  backgroundColor: cell.props.className.includes("today")
                    ? dayIndicator
                    : "",
                },
          })
        )}
      </div>
    </div>
  );
}

export default Calendar;
