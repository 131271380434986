import React, { useState } from "react";
import { ThemeContext } from "./ThemeContext";

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  const accent = "#007AFF";
  let primary;
  let systemGray5;
  let systemGray6;
  if (darkMode) {
    primary = "#fff";
    systemGray5 = "#2c2c2e";
    systemGray6 = "#1c1c1f";
  } else {
    primary = "#000";
    systemGray5 = "#e5e4e9";
    systemGray6 = "#f2f1f7";
  }
  const colorScheme = { accent, primary, systemGray5, systemGray6 };

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode, colorScheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
