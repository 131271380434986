import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../ThemeContext";

const FlashSlotRow = ({
  request,
  setRequest,
  slot,
  setSlot,
  isSelected,
  setTime,
}) => {
  const { colorScheme } = useContext(ThemeContext);
  const [slotStartString, setSlotStartString] = useState("");
  const [slotEndString, setSlotEndString] = useState("");
  const [timeOptions, setTimeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let duration = request.duration * 60 * 60 * 1000;

  useEffect(() => {
    setIsLoading(true);
    let start = new Date(slot.startString);
    let end = new Date(slot.endString);
    setSlotStartString(
      start.toLocaleTimeString("en-us", {
        hour: "numeric",
        minute: "2-digit",
      })
    );
    setSlotEndString(
      end.toLocaleTimeString("en-us", {
        hour: "numeric",
        minute: "2-digit",
      })
    );
    const adjustedEndTime = new Date(end.getTime() - duration);
    generateTimeOptions(start, adjustedEndTime, (options) => {
      setTimeOptions(options);
      let firstTimeSlot = new Date(options[0]);
      updateRequest("startDate", firstTimeSlot);
      let newEndDate = new Date(firstTimeSlot.getTime() + duration);
      updateRequest("endDate", newEndDate);
      if (request && request.date) {
        let date = new Date(request.date);
        handleTimeSelection(date);
      }
      setIsLoading(false);
    });
  }, [slot]);

  const updateRequest = (key, newValue) => {
    setRequest((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const generateTimeOptions = async (start, end, callback) => {
    const intervalMinutes = 30;
    let options = [];
    while (start < end) {
      options.push(new Date(start));
      start.setMinutes(start.getMinutes() + intervalMinutes);
    }
    callback(options);
  };

  const handleTimeSelection = (newTime) => {
    if (!isSelected) {
      setSlot(slot);
    }
    let time = new Date(newTime);
    updateRequest("startDate", time);
    let newEndDate = new Date(time.getTime() + duration);
    updateRequest("endDate", newEndDate);
  };

  function formatTime(date) {
    return date.toLocaleTimeString("en-us", {
      hour: "numeric",
      minute: "2-digit",
    });
  }

  const rowContainer = {
    width: "288px",
    display: "flex",
    flexDirection: "column",
    //alignItems: "center",

    padding: "8px 16px",
    marginBottom: "8px",
    borderRadius: "8px",
    border: isSelected
      ? `2px solid ${colorScheme.accent}`
      : `2px solid ${colorScheme.systemGray5}`,
    color: colorScheme.primary,
    backgroundColor: colorScheme.systemGray5,
  };

  const rowStyle = {
    width: "288px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
    color: colorScheme.primary,
  };

  const timeStyle = {
    width: "176px",
    margin: "0",
    fontSize: "16px",
    textAlign: "left",
    //backgroundColor: "green",
  };

  const bookStyle = {
    margin: "0",
    fontSize: "16px",
    padding: "11px 24px",
    borderRadius: "8px",
    color: "white",
    backgroundColor: colorScheme.accent,
  };

  const dropStyle = {
    fontSize: "16px",
    backgroundColor: colorScheme.systemGray6,
    border: "1px solid gray",
    borderRadius: "4px",
  };

  const fieldContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    //backgroundColor: "yellow",
  };

  const requestingStyle = {
    height: "16px",
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    fontWeight: "600",
    color: "gray",
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={rowContainer}>
      <div style={rowStyle}>
        <p style={timeStyle}>
          {slotStartString} - {slotEndString}
        </p>

        <div style={fieldContainerStyle}>
          <select
            style={dropStyle}
            id="preference"
            name="preference"
            value={request.startDate}
            onChange={(e) => handleTimeSelection(e.target.value)}
          >
            {timeOptions.map((option) => (
              <option key={option} value={option}>
                {formatTime(option)}
              </option>
            ))}
          </select>
        </div>
      </div>

      {request.startDate && isSelected && (
        <div style={requestingStyle}>
          Requesting {formatTime(request.startDate)} -{" "}
          {formatTime(request.endDate)}{" "}
        </div>
      )}
    </div>
  );
};

export default FlashSlotRow;
