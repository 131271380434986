import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "./ThemeContext";
import { ReactComponent as CopyIcon } from "./assets/square.on.square.svg";

function Profile({ profile }) {
  const { colorScheme } = useContext(ThemeContext);

  // Copy function
  const copyToClipboard = (e) => {
    const userProfileURL = `https://${profile.fields.username.value}.tattsafe.us`;
    const textarea = document.createElement("textarea");
    textarea.value = userProfileURL;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
      console.log("URL copied to clipboard");
    } catch (err) {
      console.log("Unable to copy", err);
    }
    document.body.removeChild(textarea);
  };

  const bannerStyle = {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "auto",
  };

  const profileStyle = {
    display: "flex",
    //alignItems: "flex-start",
    //justifyContent: "flex-start",
    backgroundColor: colorScheme.systemGray5,
    paddingBottom: "20px",
    position: "relative",
  };

  const profilePicStyle = {
    borderRadius: "50%",
    height: "80px",
    marginRight: "20px",
    marginLeft: "10%",
    position: "absolute",
    top: "calc(-30px)",
    zIndex: 1,
    border: `4px solid ${colorScheme.systemGray5}`,
  };

  const userInfoStyle = {
    marginLeft: "calc(10% + 100px)",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    color: colorScheme.primary,
    //backgroundColor: "pink",
  };

  const displayNameStyle = {
    fontWeight: "bold",
    color: colorScheme.primary,
    margin: "0",
    marginTop: "3px",
    marginBottom: "5px",
  };

  const usernameStyle = {
    alignItems: "flex-start",
    justifyContent: "flex-start",
    color: "gray",
    margin: "0",
  };

  const copyButtonStyle = {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",
    marginLeft: "0px",
  };

  return (
    <div>
      <img
        style={bannerStyle}
        src={profile.fields.banner.value.downloadURL}
        alt="Banner"
      />

      <div style={profileStyle}>
        <img
          style={profilePicStyle}
          src={profile.fields.propic.value.downloadURL}
          alt="Profile Pic"
        />
        <div style={userInfoStyle}>
          <p style={displayNameStyle}>{profile.fields.displayName.value}</p>
          <p style={usernameStyle}>@{profile.fields.username.value}</p>
        </div>
      </div>
    </div>
  );
}

export default Profile;

{
  /* <button onClick={copyToClipboard} style={copyButtonStyle}>
  <CopyIcon width="12px" height="12px" aria-label="copy" />
</button>; */
}
