import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "./ThemeContext";
import Home from "./Home";
import FlashController from "./Flash/FlashController";
import BookingController from "./Booking/BookingController";
import Data from "./Data";
import Test from "./Test";

function AppView({ profile }) {
  const { darkMode, setDarkMode, colorScheme } = useContext(ThemeContext);
  const [showData, setShowData] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [selectedSlot, setSelectedSlot] = useState(null);
  const setSlot = (slot) => {
    setSelectedSlot(slot);
    console.log("slot", slot);
  };

  const [flashData, setFlashData] = useState(null);
  const [flashIndex, setFlashIndex] = useState(null);
  useEffect(() => {
    if (flashData) {
      console.log(flashData[flashIndex]);
    }
  }, [flashIndex]);
  const setFlash = (data) => {
    setFlashData(data);
  };
  const setIndex = (index) => {
    setFlashIndex(index);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => {
    setFlashIndex(null);
    setSelectedSlot(null);
  };

  return (
    <div>
      <div style={{ position: "fixed", zIndex: 5 }}>
        {showTest && <Test profile={profile} />}
      </div>
      <div style={{ position: "fixed", zIndex: 4 }}>
        {showData && <Data onClose={() => setShowData(false)} />}
      </div>
      <div style={{ position: "fixed", zIndex: 3 }}>
        {flashData && flashIndex && (
          <FlashController
            profile={profile}
            flashData={flashData}
            initialIndex={flashIndex}
            handleClose={handleClose}
          />
        )}
      </div>
      <div style={{ position: "fixed", zIndex: 2 }}>
        {selectedSlot && (
          <BookingController
            profile={profile}
            selectedSlot={selectedSlot}
            handleClose={handleClose}
          />
        )}
      </div>

      <div style={{ zIndex: 1 }}>
        <Home
          profile={profile}
          setSlot={setSlot}
          flashData={flashData}
          setFlashData={setFlash}
          flashIndex={setIndex}
        />
      </div>
    </div>
  );
}

export default AppView;
