import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import MobileBookingInfo from "./MobileBookingInfo";
import MobileBookingUserInfo from "./MobileBookingUserInfo";
import BookingSuccess from "./BookingSuccess";
import { ReactComponent as XMark } from "../assets/xmark.svg";

const BookingController = ({ profile, selectedSlot, handleClose }) => {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState("bookingInfo");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestResponse, setRequestResponse] = useState(null);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(null);
  const [images, setImages] = useState([]);
  const [request, setRequest] = useState({
    alertBody: "",
    colorSpace: "gray",
    date: "",
    deposit: 0,
    dob: null,
    duration: 0,
    email: "",
    endDate: null,
    familyName: "",
    flash: "",
    givenName: "",
    height: "",
    locationOnBody: "",
    notes: "",
    phone: "",
    profile: "",
    referenceImages: [],
    subtitle: "",
    startDate: null,
    title: "New Request",
    total: 0,
    type: "booking",
    width: "",
  });

  function mobile() {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    if (isMobile) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (isLoading) {
      let startDate = new Date(selectedSlot.startString);
      let dateString = startDate.toLocaleDateString("en-us", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      setRequest((prevData) => ({
        ...prevData,
        date: selectedSlot.startString,
        endDate: new Date(selectedSlot.endString),
        profile: profile.recordName,
        startDate: startDate,
        subtitle: dateString,
      }));
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    console.log("isSubmitting", isSubmitting);
  }, [isSubmitting]);

  // useEffect(() => {
  //   console.log("request.referenceImages", request);
  // }, [request.referenceImages]);

  useEffect(() => {
    console.log("requestResponse", requestResponse);
  }, [requestResponse]);

  useEffect(() => {
    console.log("failure set", failure);
    if (failure) {
      alert(failure);
    }
  }, [failure]);

  useEffect(() => {
    console.log("success", success);
    if (success) {
      setView("success");
    }
  }, [success]);

  const toUserInfo = () => {
    if (request.width == "") {
      alert(
        "Please enter a width. If you are unsure, your best estimation is fine."
      );
      return;
    } else if (request.height == "") {
      alert(
        "Please enter a height. If you are unsure, your best estimation is fine."
      );
      return;
    } else if (request.locationOnBody == "") {
      alert(
        "Please describe, in a few words or less, where on your body you're interested in getting your tattoo."
      );
      return;
    } else if (request.notes == "") {
      alert("Please provide a description of your tattoo.");
      return;
    }
    setView("userInfo");
  };

  const toBookingInfo = () => {
    setView("bookingInfo");
  };

  const toVerification = () => {
    setView("verify");
  };

  const calculateAge = (dob, apptDate) => {
    let age = apptDate.getFullYear() - dob.getFullYear();
    const m = apptDate.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && apptDate.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  };

  const handleImagesUpload = async (callback) => {
    let errors = [];
    let referenceImages = [];
    for (let img of images) {
      try {
        const base64String = img.split(",")[1];
        const response = await fetch(
          "https://bp5k87wfe9.execute-api.us-east-1.amazonaws.com/Prod",
          {
            method: "POST",
            body: base64String,
            headers: {
              "Content-Type": "text/plain",
            },
          }
        );
        const data = await response.json();
        const recordName =
          data.records && data.records[0] && data.records[0].recordName;
        if (recordName) {
          console.log("Image uploaded:", data);
          referenceImages.push(recordName);
        }
      } catch (error) {
        errors.push(error);
      }
    }

    if (errors.length == 0) {
      console.log("no upload errors");
    }
    callback(errors, referenceImages);
  };

  const sendRequest = async (requestData, callback) => {
    const url = `https://usyu59afm2.execute-api.us-east-1.amazonaws.com/Prod`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      console.error("Response", data);
      if (response.status === 200) {
        callback(null, data);
        return;
      }

      callback(data.message, null);
    } catch (error) {
      console.error("Error sending request:", error);
      callback(error, null);
    }
  };

  const handleSubmit = async () => {
    console.log("handleSubmit called");
    if (isSubmitting) {
      console.log("Processing, please wait...");
      return;
    }

    if (request.dob) {
      const age = calculateAge(new Date(request.dob), request.startDate);
      if (age < 18) {
        alert(
          "You must be 18 years old at the time of the appointment to submit a request."
        );
        return;
      }
    } else {
      alert(
        "Please enter a Date of Birth.\n\nYou must be 18 years old at the time of the appointment to submit a request."
      );
      return;
    }

    if (request.givenName == "" || request.givenName.trim() === "") {
      alert("Please enter your first name.");
      return;
    } else if (request.familyName == "" || request.familyName.trim() === "") {
      alert("Please enter your last name.");
      return;
    } else if (
      !request.phone ||
      request.phone.trim() === "" ||
      request.phone.length != 14
    ) {
      alert("Please provide a valid phone number.");
      return;
    } else if (request.email == "" || request.email.trim() === "") {
      alert("Please enter your email address.");
      return;
    }

    setIsSubmitting(true);
    let currentRequest = request;
    currentRequest.alertBody = currentRequest.givenName;
    console.log("currentRequest.alertBody", currentRequest);

    await handleImagesUpload(async (errors, referenceImages) => {
      console.log("handleImagesUpload callback executed");
      if (errors && errors.length > 0) {
        errors.forEach((err) => alert(err));
      } else if (referenceImages.length == images.length) {
        currentRequest.referenceImages = referenceImages;
        console.log("Image upload successful!", currentRequest);
        sendRequest(currentRequest, (error, response) => {
          if (error) {
            setFailure(error);
          }
          if (
            response &&
            response.records &&
            response.records[0] &&
            response.records[0].recordName
          ) {
            const record = response.records[0];
            setRequestResponse(record);
            setSuccess(true);
          }
        });
      } else {
        alert("Reference images did not upload properly.");
      }
      setIsSubmitting(false);
    });
  };

  const renderView = () => {
    switch (view) {
      case "bookingInfo":
        return (
          <MobileBookingInfo
            request={request}
            setRequest={setRequest}
            images={images}
            setImages={setImages}
            toUserInfo={toUserInfo}
          />
        );
      case "userInfo":
        return (
          <MobileBookingUserInfo
            request={request}
            setRequest={setRequest}
            toBookingInfo={toBookingInfo}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        );
      case "success":
        return (
          <BookingSuccess
            requestResponse={requestResponse}
            handleClose={handleClose}
          />
        );
      default:
        return (
          <MobileBookingInfo
            profile={profile}
            selectedSlot={selectedSlot}
            toUserInfo={toUserInfo}
          />
        );
    }
  };

  const bg = {
    zIndex: 2,
    position: "absolute",
    top: 0,
    display: "flex",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    border: "none",
    justifyContent: "center",
    alignItems: "flex-start",
  };

  const xmarkButton = {
    position: "absolute",
    top: "0",
    right: "0",
    padding: mobile() ? "8px" : "16px",
    margin: "0",
    border: "none",
    boxSizing: "0",
    backgroundColor: "rgba(0, 0, 0, 0.0)",
  };

  const xmark = {
    width: mobile() ? "32px" : "48px",
    height: mobile() ? "32px" : "48px",
    fill: "#fff",
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={bg}>
      <button style={xmarkButton} onClick={handleClose}>
        <XMark style={xmark} />
      </button>
      {renderView()}
    </div>
  );
};

export default BookingController;
