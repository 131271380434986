import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";
import { ReactComponent as ChevronLeft } from "../assets/chevron.left.svg";

function MobileBookingInfo({
  request,
  setRequest,
  images,
  setImages,
  toUserInfo,
}) {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState([]);
  //const [images, setImages] = useState([]);

  let dateString = request.startDate.toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  let startTime = request.startDate.toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "2-digit",
  });
  let endTime = request.endDate.toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "2-digit",
  });

  useEffect(() => {
    //console.log("tattsafe");
    setIsLoading(false);
  }, []);

  const updateRequest = (key, newValue) => {
    setRequest((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/png"];

    if (file && allowedFileTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((prevImages) => [...prevImages, reader.result]);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload a valid image file (JPEG or PNG).");
    }
  };

  const removeImage = (indexToRemove) => {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleImagesUpload = async () => {
    for (let img of images) {
      try {
        const base64String = img.split(",")[1];
        const response = await fetch(
          "https://bp5k87wfe9.execute-api.us-east-1.amazonaws.com/Prod",
          {
            method: "POST",
            body: base64String,
            headers: {
              "Content-Type": "text/plain",
            },
          }
        );
        const data = await response.json();
        console.log("Image uploaded:", data);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const cardContainer = {
    zIndex: 3,
    position: "absolute",
    top: "24px",
    left: (window.innerWidth - 296) / 2,
    width: "296px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.systemGray6,
  };

  const cardStyle = {
    fontSize: "flex",
    fontWeight: "500",
    margin: "2px",
    opacity: "0.8",
  };

  const container = {
    zIndex: 1,
    position: "absolute",
    top: "48px",
    left: (window.innerWidth - 360) / 2,
    width: "360px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "16px",
    backgroundColor: colorScheme.systemGray6,
  };

  const rowStyle = {
    width: "280px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "8px",
    color: colorScheme.primary,
    //backgroundColor: "blue",
  };

  const sizeRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "center",
    color: colorScheme.primary,
    //backgroundColor: "pink",
  };

  const labelStyle = {
    margin: "0",
    fontSize: "16px",
    textAlign: "left",
    //backgroundColor: "green",
  };

  const inputContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    //backgroundColor: "yellow",
  };

  const inputLabel = {
    fontSize: "8px",
    fontWeight: "600",
    marginLeft: "8px",
    margin: "2px",
    color: "gray",
    //backgroundColor: "red",
  };

  const inputStyle = {
    fontSize: "16px",
    border: "1px solid gray",
    borderRadius: "4px",
    backgroundColor: colorScheme.systemGray5,
  };

  const sizeInput = {
    width: "32px",
    textAlign: "right",
    padding: "2px 4px",
    marginLeft: "4px",
    marginRight: "2px",
  };

  const bodyInput = {
    width: "128px",
    textAlign: "right",
  };

  const notesInput = {
    width: "256px",
    height: "88px",
  };

  const imagesContainer = {
    width: "288px",
    height: "72px",
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    marginTop: "8px",
    //backgroundColor: "pink",
  };

  const uploadedImages = {
    width: "auto",
    height: "64px",
    borderRadius: "4px",
    marginRight: "8px",
  };

  const buttonContainer = {
    width: "312px",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
    //backgroundColor: "pink",
  };

  const button = {
    position: "relative",
    height: "40px",
    fontSize: "16px",
    color: "white",
    top: "24px",
    cursor: "pointer",
    border: "none",
    borderRadius: "4px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={cardContainer}>
        <p style={{ ...cardStyle, marginTop: "8px" }}>{dateString}</p>
        <p style={{ ...cardStyle, marginBottom: "8px" }}>
          {startTime} - {endTime}
        </p>
      </div>

      <div style={container}>
        <div style={{ height: "48px" }}></div>
        <div style={{ ...rowStyle, alignItems: "end" }}>
          <p style={labelStyle}>Size</p>
          <div style={sizeRow}>
            <div style={{ ...inputContainer, alignItems: "center" }}>
              <label style={inputLabel} htmlFor="width">
                WIDTH
              </label>
              <input
                value={request.width}
                onChange={(e) => updateRequest("width", e.target.value)}
                style={{ ...inputStyle, ...sizeInput }}
                type="text"
                id="width"
                name="width"
              />
            </div>
            in X
            <div style={{ ...inputContainer, alignItems: "center" }}>
              <label style={inputLabel} htmlFor="givenName">
                HEIGHT
              </label>
              <input
                value={request.height}
                onChange={(e) => updateRequest("height", e.target.value)}
                style={{ ...inputStyle, ...sizeInput }}
                type="text"
                id="height"
                name="height"
              />
            </div>
            in
          </div>
        </div>

        <div style={rowStyle}>
          <p style={labelStyle}>Location on body</p>

          <input
            value={request.locationOnBody}
            onChange={(e) => updateRequest("locationOnBody", e.target.value)}
            style={{ ...inputStyle, ...bodyInput }}
            type="text"
            id="locationOnBody"
            name="locationOnBody"
          />
        </div>

        <div style={{ ...rowStyle, width: "256px" }}>
          <label style={{ fontSize: "16px" }}>
            <input
              style={{ marginRight: "8px" }}
              type="radio"
              name="colorOption"
              value="color"
              onChange={(e) => updateRequest("colorSpace", e.target.value)}
              checked={request.colorSpace === "color"}
            />
            Color
          </label>

          <label style={{ fontSize: "16px" }}>
            <input
              style={{ marginRight: "8px" }}
              type="radio"
              name="colorOption"
              value="gray"
              onChange={(e) => updateRequest("colorSpace", e.target.value)}
              checked={request.colorSpace === "gray"}
            />
            Black & White
          </label>
        </div>

        <div style={{ ...inputContainer, marginBottom: "8px" }}>
          <label style={inputLabel} htmlFor="email">
            Notes for the Artist
          </label>
          <textarea
            value={request.notes}
            onChange={(e) => updateRequest("notes", e.target.value)}
            maxLength={256}
            style={{ ...inputStyle, ...notesInput }}
            placeholder="Enter your text here..."
          />
          <div style={inputLabel}>
            {256 - request.notes.length} characters remaining
          </div>
        </div>

        <div style={inputContainer}>
          <label style={inputLabel} htmlFor="email">
            Attach Reference Photos
          </label>
          <input
            style={{ marginLeft: "4px" }}
            type="file"
            accept=".jpeg, .jpg, .png"
            onChange={handleImageChange}
          />
          <div style={imagesContainer}>
            {images.map((image, index) => (
              <div
                key={index}
                style={{ position: "relative", display: "inline-block" }}
              >
                <img
                  src={image}
                  alt={`Thumbnail ${index}`}
                  style={uploadedImages}
                />
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 8,
                    cursor: "pointer",
                    background: "rgba(255, 255, 255, 0.7)",
                    borderRadius: "50%",
                    padding: "2px 5px",
                    lineHeight: "1",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                  onClick={() => removeImage(index)}
                >
                  x
                </span>
              </div>
            ))}
          </div>
        </div>

        <div style={buttonContainer}>
          <button
            style={{ ...button, width: "96px" }}
            onClick={() => toUserInfo()}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobileBookingInfo;
