import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../ThemeContext";
import { ReactComponent as XMark } from "../assets/xmark.svg";
import { ReactComponent as ChevronLeft } from "../assets/chevron.left.svg";
import { ReactComponent as ChevronRight } from "../assets/chevron.right.svg";
import FlashCard from "./FlashCard";
import MobileFlashCard from "./MobileFlashCard";

function FlashCarousel({ flashData, initialIndex, setFlash, toCalendar }) {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [currentFlashIndex, setCurrentFlashIndex] = useState(initialIndex);

  useEffect(() => {
    setFlash(flashData[currentFlashIndex]);
  }, [currentFlashIndex]);

  const handleNextFlash = () => {
    setCurrentFlashIndex((prevIndex) => (prevIndex + 1) % flashData.length);
  };

  const handlePreviousFlash = () => {
    setCurrentFlashIndex(
      (prevIndex) => (prevIndex + flashData.length - 1) % flashData.length
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FlashCard
      flash={flashData[currentFlashIndex]}
      next={handleNextFlash}
      previous={handlePreviousFlash}
      toCalendar={toCalendar}
    />
  );
}

export default FlashCarousel;
