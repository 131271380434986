import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../ThemeContext";

function BookingSuccess({ requestResponse, handleClose }) {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);
  let date = new Date(requestResponse.fields.date.value);
  let dateString = date.toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  useEffect(() => {
    if (requestResponse) {
      setIsLoading(false);
    }
  }, []);

  const cardContainer = {
    zIndex: 3,
    position: "absolute",
    top: "24px",
    left: (window.innerWidth - 296) / 2,
    width: "296px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.systemGray6,
  };

  const cardText = {
    fontSize: "16px",
    fontWeight: "500",
    margin: "2px",
    opacity: "0.8",
  };

  const container = {
    zIndex: 1,
    position: "absolute",
    top: "48px",
    left: (window.innerWidth - 360) / 2,
    width: "360px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "16px",
    backgroundColor: colorScheme.systemGray6,
  };

  const containerRow = {
    width: "256px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  };

  const buttonContainer = {
    width: "312px",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
    //backgroundColor: "pink",
  };

  const button = {
    position: "relative",
    height: "40px",
    fontSize: "16px",
    color: "white",
    top: "24px",
    cursor: "pointer",
    border: "none",
    borderRadius: "4px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
    backgroundColor: colorScheme.accent,
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={cardContainer}>
        <p style={{ ...cardText, marginTop: "8px" }}>Request Submitted!</p>
        <p style={{ ...cardText, marginBottom: "8px" }}>
          Confirmation {requestResponse.created.timestamp}
        </p>
      </div>

      <div style={container}>
        <div style={{ height: "48px" }}></div>

        <p style={{ margin: "2px 0px" }}>{dateString}</p>

        <div style={{ ...containerRow, borderBottom: "1px solid lightgray" }}>
          Size
          <p style={{ margin: "2px 0px" }}>
            {requestResponse.fields.width.value}in X{" "}
            {requestResponse.fields.height.value}in
          </p>
        </div>

        <div style={{ ...containerRow, borderBottom: "1px solid lightgray" }}>
          Location on body
          <p style={{ margin: "2px 0px" }}>
            {requestResponse.fields.locationOnBody.value}
          </p>
        </div>

        <div style={{ ...containerRow, borderBottom: "1px solid lightgray" }}>
          Color space
          <p style={{ margin: "2px 0px" }}>
            {requestResponse.fields.colorSpace.value == "gray"
              ? "Black & White"
              : "Color"}
          </p>
        </div>

        <div style={containerRow}>
          Reference images
          <p style={{ margin: "2px 0px" }}>
            {requestResponse.fields.referenceImages.value.length}
          </p>
        </div>

        <p style={{ margin: "4px 0px" }}>
          {requestResponse.fields.notes.value}
        </p>

        <div style={buttonContainer}>
          <button
            style={{ ...button, width: "96px" }}
            onClick={() => handleClose()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default BookingSuccess;
