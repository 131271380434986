import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../ThemeContext";
import { ReactComponent as XMark } from "../assets/xmark.svg";
import { ReactComponent as ChevronLeft } from "../assets/chevron.left.svg";
import { ReactComponent as ChevronRight } from "../assets/chevron.right.svg";
import MobileFlashCard from "./MobileFlashCard";

function ImageCarousel({
  flashData,
  initialIndex,
  setFlash,
  toCalendar,
  onClose,
}) {
  const { colorScheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [currentFlashIndex, setCurrentFlashIndex] = useState(initialIndex);

  useEffect(() => {
    setFlash(flashData[currentFlashIndex]);
  }, [currentFlashIndex]);

  const handleNextFlash = () => {
    setCurrentFlashIndex((prevIndex) => (prevIndex + 1) % flashData.length);
  };

  const handlePreviousFlash = () => {
    setCurrentFlashIndex(
      (prevIndex) => (prevIndex + flashData.length - 1) % flashData.length
    );
  };

  const handleClose = () => {
    onClose();
  };

  const containerStyle = {
    position: "absolute",
    //right: "0",
    //top: "0",
    padding: "8px",
    //backgroundColor: "rgba(0, 0, 0, 0.2)",
    //borderRadius: "8px",
    margin: "10px",
    border: "0",
    //width: "100%",
    //justifyContent: "center",
    //backgroundColor: "yellow",
  };

  const chevronButtonStyle = {
    position: "absolute",
    padding: "10px",
    paddingTop: "7px",
    paddingBottom: "5px",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    border: "0",
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <MobileFlashCard
      flash={flashData[currentFlashIndex]}
      next={handleNextFlash}
      previous={handlePreviousFlash}
      toCalendar={toCalendar}
    />
  );
}

export default ImageCarousel;
